.thank-you-container {
    background-color: #4f00e0; /* Matching background color */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align content starting from the top */
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 2rem;
  }
  
  .thank-you-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #4f00e0;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 2rem; /* Space above the thank you message */
    width: 80%; /* Adjust width if you want it to be wider or narrower */
  }
  
  .thank-you-message h2,
  .thank-you-message p {
    margin: 0.5rem 0;
  }
  
  .return-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    background-color: white;
    color: #4f00e0;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 1rem; /* Space between the button and the message */
  }
  
  .return-button:hover {
    background-color: #e0e0e0;
    transition: background-color 0.3s;
  }
  
  .thank-you-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem; /* Space below the logo */
  }
  
  .thank-you-logo-image {
    height: 35vmin;
    pointer-events: none;
  }
  
  .thank-you-footer {
    margin-top: auto; /* Push footer to the bottom */
    padding: 1rem;
    text-align: center;
    color: white;
  }
  