.login-container {
  background-color: #4f00e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align content starting from the top */
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 2rem;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem; /* Adjust margin if needed */
}

.logo-image {
  height: 35vmin;
  pointer-events: none;
}

.payment-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #4f00e0;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem; /* Less space between details and button */
  margin-top: 2rem; /* Adjust this to move it up */
  width: 80%; /* Adjust width if you want it to be wider or narrower */
}

.payment-details h2,
.payment-details p {
  margin: 0.5rem 0;
}

/* Common button styles for both buttons */
button,
#card-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: white;
  color: #4f00e0;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 1rem; /* Space between the button and the payment details */
}

button:hover,
#card-button:hover {
  background-color: #e0e0e0;
  transition: background-color 0.3s;
}

/* Payment form and status */
#payment-form {
  margin-top: 20px;
}

#card-container {
  margin-bottom: 20px;
}

#payment-status-container {
  margin-bottom: 20px;
  color: white;
}

.sq-card-message {
  color: white;
}

.sq-card-message-no-error {
  color: white;
}

.sq-card-wrapper .sq-card-message-error {
  color: teal;
}
