/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.App {
  text-align: center;
}

.App-logo {
  height: 70vmin;
  pointer-events: none;
}

.App-logo {
  position: relative;
}

.App-body {
  background-color: #4f00e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Play-store {
  height: 125px;
}

.App-store {
  height: 150px;
}

.App-footer {
  background-color: black;
  display: block;
  color: white;
  font-size: calc(1px + 2vmin);
  flex-direction: column;
  align-items: baseline;
}

footer {
  background-color: black;
  margin-top: auto;
  padding: 8px;
  text-align: center;
  color: white;
}
