.login-container {
  background-color: #4f00e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 1rem;
}

.login-container h2 {
  margin-bottom: 1rem;
}

.login-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.login-container form div {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.login-container form label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.login-container form input {
  padding: 0.5rem;
  font-size: 1rem;
  background-color: white;
  color: #4f00e0;
  border: none;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
}

.login-container form button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: white;
  color: #4f00e0;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 7rem;
  border-radius: 4px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.logo-image {
  height: 70vmin;
  pointer-events: none;
}

.login-container form button:hover {
  background-color: #e0e0e0;
  transition: background-color 0.3s;
}
