  .about-container {
    background-color: #4f00e0;
    color: white;
    height: 80vh;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .header h1 {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .content {
    text-align: center;
    margin: 2rem;
  }
  
  p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
  