.contact-container {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #4f00e0;
  color: white;
  border: none;
  cursor: pointer;
}


.contact-container input[type="text"],
.contact-container input[type="email"],
.contact-container textarea {
  padding: 0.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  background-color: white;
  color: #4f00e0;
  width: 100%;
  max-width: 300px;
  margin-bottom: 1rem;
}

.contact-container label {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}

.contact-container textarea {
  height: 150px;
}

.contact-container .message-label {
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: -0.5rem;
}

.contact-container button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: white;
  color: #4f00e0;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 7rem;
  border-radius: 4px;
}
