.navbar {
  background-color: #4f00e0;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.navbar-list {
  list-style-type: none;
  display: flex;
  padding: 0;
}

.navbar-item {
  margin: 0 10px;
}

.navbar-item .navbar-link {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

.navbar-item .navbar-link:hover {
  color: #ccc;
}

.navbar-item .logout-button {
  background-color: transparent;
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}

.navbar-item .logout-button:hover {
  text-decoration: none;
}
