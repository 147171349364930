.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    line-height: 1.6;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  p {
    margin-bottom: 10px;
  }
  