.logo {
  height: 70vmin;
  pointer-events: none;
}

.App-logo {
  position: relative;
}

.App-body {
  background-color: #4f00e0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Play-store {
  height: 125px;
}

.App-store {
  height: 150px;
}