.admin-container {
    background-color: #4f00e0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 1rem;
  }
  
  .admin-container h2 {
    margin-bottom: 1rem;
  }
  
  .admin-container p {
    margin-bottom: 1rem;
  }

  .admin-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  .admin-table th,
  .admin-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  .admin-table th {
    background-color: #f2f2f2;
  }

  